<template>
    <div>
        <div class="tabs">
            <span :class="{ active: tabs === 1 }" @click="tabs = 1">活动</span>
            <span :class="{ active: tabs === 9 }" @click="tabs = 9">课程</span>
            <span :class="{ active: tabs === 4 }" @click="tabs = 4">帖子</span>
        </div>
        <div class="list" v-if="tabs === 1">
            <div class="item" v-for="(item, index) in list" :key="index" @click="goHref(item.id)">
                <img class="active" :src="item.picUrl[0]" alt="" />
                <div class="info">
                    <div class="title">{{ item.name }}</div>
                    <div class="status">{{ statusArr[item.status] }}</div>
                    <div class="tips">
                        <div class="tip">{{ hold[item.type].label }}</div>
                        <div class="tip" v-if="item.isFree == 1">免费</div>
                    </div>
                    <div class="time">
                        <img src="@/assets/images/Frame-21.png" alt="" />
                        <span>{{ item.createTime }}</span>
                    </div>
                    <div class="address">
                        <img src="@/assets/images/Frame-20.png" alt="" />
                        <span>{{ item.libraryAddress }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="list" v-if="tabs === 9">
            <div class="item" v-for="(item, index) in list" :key="index" @click="goHref(item.id)">				
                <img v-if="item['moocChapterList'].length>0" class="active" 
					:src="item['moocChapterList'][0].videoUrl1 + '?x-oss-process=video/snapshot,t_0,f_jpg'" alt="" />
                <div class="info">
                    <div class="title">{{ item.curriculumName }}</div>
                    <div class="tips">
                        <div class="tip">{{ hold[item.type].label }}</div>
                        <div class="tip" v-if="item.isFree == 1">免费</div>
                    </div>
                    <div class="time">
                        <span>授课老师：{{ item.teacherName }}</span>
                    </div>
                    <div class="address">
                        <img src="@/assets/images/Frame-38.png" alt="" />
                        <span>{{ item.playerCount }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="list1" v-if="tabs === 4">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/communityPostsDetail', query: { id: item.id } })">
                <div class="top">
                    <div class="left">
                        <img :src="item.avatar" alt="" />
                        <span>{{ item.nickName }}</span>
                        <span>{{ item.createTime }}</span>
                    </div>
                    <div class="right">
                        <div class="items" @click.stop="collection(item)">
                            <!--                         eslint-disable-next-line -->
                            <img :src="item.collectionId ? require('../../../assets/images/Frame-32.png') : require('../../../assets/images/Frame-31.png')" />
                            <!--<img src="@/assets/images/Frame-31.png" alt="" />-->
                            <span>{{ item.collectionCount || 0 }}</span>
                        </div>
                        <div class="items">
                            <img src="@/assets/images/Group622.png" alt="" />
                            <span>{{ item.commentCount || 0 }}</span>
                        </div>
                        <div class="items" @click.stop="praise(item)">
                            <!-- <img src="@/assets/images/Frame-53.png" alt="" />-->
                            <!--                          eslint-disable-next-line-->
                            <img :src="item.praiseId ? require('../../../assets/images/Frame-30.png') : require('../../../assets/images/Frame-29.png')" />
                            <span>{{ item.praiseCount || 0 }}</span>
                        </div>
                    </div>
                </div>
                <div class="content">{{ item.content }}</div>
                <div class="img">
                    <div class="" v-for="(t, i) in item.picUrl" :key="i" @click.stop="() => {}">
                        <el-image style="width: 100%; height: 130px" :src="t" :preview-src-list="item.picUrl"></el-image>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getCollectList" @current-change="getCollectList" :current-page.sync="page" :page-sizes="[10, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getCollectList } from '@/api';

export default {
    name: 'Collection',
    data() {
        return {
            tabs: 1,
            limit: 10,
            page: 1,
            total: 0,
            list: [],
            statusArr: ['即将开始', '进行中', '已结束'], //  0未开始，1进行中，2已结束
            hold: [
                {
                    label: '全部',
                    value: '',
                },
                {
                    label: '演出',
                    value: 0,
                },
                {
                    label: '讲座',
                    value: 1,
                },
                {
                    label: '展览',
                    value: 2,
                },
                {
                    label: '会议',
                    value: 3,
                },
            ],
        };
    },
    watch: {
        tabs() {
            this.page = 1;
            this.getCollectList();
        },
    },
    created() {
        this.getCollectList();
    },
    methods: {
        getCollectList() {
            let data = {
                pageNum: this.page,
                pageSize: this.limit,
                type: this.tabs,
            };
            getCollectList(data).then((res) => {
                // console.log(res);
                res.data.forEach((item) => {
					if(item.picUrl) {
						item.picUrl = item.picUrl.split(',');						
					}
					if(item.moocChapterList && item.moocChapterList.length>0){
						item.moocChapterList.forEach((mooc) =>{
							if (mooc.videoUrl) {
								mooc.videoUrl1 = mooc.videoUrl.split(',')[0];
							}
						})
					}
                });
                this.list = res.data;
                this.total = res.total || 0;
            });
        },
        goHref(id) {
            if (this.tabs == 1) {
                this.$router.push({ path: '/excitingActivitiesDetail', query: { id: id } });
            } else if (this.tabs == 9) {
                this.$router.push({ path: '/courseTrainingDetail', query: { id: id } });
            } else {
                this.$router.push({ path: '/postDetail', query: { id: id } });
            }
        },
        // 收藏
        collection(item) {
            console.log(item);
            if (item.collectionId === null) {
                this.$hao.addPraiseCollection({ relatedId: item.id, type: 4 }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('收藏成功');
                        item.collectionId = res.data.id;
                        item.collectionCount++;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$hao.delPraiseCollection(item.collectionId).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('取消收藏成功');
                        item.collectionId = null;
                        item.collectionCount--;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 点赞
        praise(item) {
            if (item.praiseId === null) {
                this.$hao.addPraiseCollection({ relatedId: item.id, type: 8 }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('点赞成功');
                        item.praiseId = res.data.id;
                        item.praiseCount++;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$hao.delPraiseCollection(item.praiseId).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('取消点赞成功');
                        item.praiseId = null;
                        item.praiseCount--;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
.tabs {
    display: flex;
    align-items: center;
    padding-top: 24px;
    overflow: hidden;
    border-radius: 3px;
    cursor: pointer;

    span {
        padding: 6.5px 10px;
        font-size: 14px;
        line-height: 17px;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #fff;
    }

    .active {
        color: #fff;
        background: #eda200;
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            position: relative;
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
                width: 81%;
                .ellipsisLine(2);
            }

            .status {
                position: absolute;
                right: 12px;
                top: 12px;
                font-size: 12px;
                line-height: 17px;
                color: #ffffff;
                padding: 4px 6px;
                background: #f33f3e;
                border-radius: 2px;
            }

            .tips {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .tip {
                    padding: 4px 12px;
                    background: rgba(243, 63, 62, 0.1);
                    border-radius: 2px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-right: 8px;
                    font-size: 13px;
                    line-height: 19px;
                    color: #f33f3e;
                }
            }

            .time {
                display: flex;
                align-items: center;
                margin-top: 8px;

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                }

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 8px;

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                }

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }
        }
    }
}

.list1 {
    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;
        padding: 24px;

        .top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 8px;
                }

                span {
                    margin-right: 8px;
                    font-weight: 400;
                    font-size: 13px;
                    color: #333333;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                span:last-child {
                    color: #999999;
                }
            }

            .right {
                display: flex;
                align-items: center;

                .items {
                    display: flex;
                    align-items: center;
                    margin-left: 24px;

                    img {
                        width: 18px;
                        height: 18px;
                        margin-right: 4px;
                    }

                    span {
                        font-size: 14px;
                        line-height: 20px;
                        color: #797a87;
                    }
                }
            }
        }

        .content {
            padding: 12px 0;
            font-size: 14px;
            line-height: 17px;
            color: #333333;
        }

        .img {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 4px;
            width: 50%;
            img {
                width: 100%;
                margin-top: 4px;
            }
        }
    }
}
</style>
