<template>
    <div class="box">
        <div class="headPortrait">
            <div class="title">
                <img src="@/assets/images/Frame652.png" alt="" />
                <span>头像：</span>
            </div>

            <el-upload :multiple="true" :show-file-list="false" action="#" :http-request="upDate" :limit="1">
                <div class="value">
                    <img :src="userInfo.avatar" alt="" />
                    <img src="@/assets/images/Frame1047.png" alt="" />
                </div>
            </el-upload>
        </div>
        <div class="name">
            <div class="title">
                <img src="@/assets/images/Frame652.png" alt="" />
                <span>姓名：</span>
            </div>
            <div class="value">
                <el-input v-model="userInfo.nickName" placeholder="请输入姓名"></el-input>
            </div>
        </div>
        <div class="sex">
            <div class="title">
                <img src="@/assets/images/Frame652.png" alt="" />
                <span>性别：</span>
            </div>
            <div class="value">
                <el-select v-model="userInfo.sex" placeholder="请选择性别">
                    <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
        </div>
        <div class="butt" @click="updateUserInfo">保存</div>
    </div>
</template>

<script>
import uploadFile from '@/utils/oss/ossClient';
import { updateUserInfo } from '@/api';
export default {
    name: 'UserInfo',
    data() {
        return {
            username: '',
            sex: '',
            sexList: [
                { label: '男', value: '0' },
                { label: '女', value: '1' },
            ],
            userInfo: {},
        };
    },
    created() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    },
    methods: {
        handleChange(file, fileList) {
            console.log(file, fileList);
        },
        upDate(e) {
            uploadFile(e).then((res) => {
                this.userInfo.avatar = res[0];
            });
        },
        updateUserInfo() {
            updateUserInfo(this.userInfo).then((res) => {
                console.log(res);
                if (res.code === 200) {
                    this.$message.success('修改成功');
                    localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style scoped lang="less">
.box {
    .title {
        display: flex;
        margin-top: 10px;
        align-items: center;
        img {
            width: 7px;
            height: 17px;
            margin-right: 2px;
        }
        span {
            font-size: 14px;
            line-height: 17px;
            color: #606266;
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }
    .headPortrait {
        margin-top: 24px;
        .value {
            display: flex;
            align-items: center;
            margin-top: 12px;
            position: relative;
            width: 80px;
            height: 80px;
            img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }
            img:last-child {
                width: 24px;
                height: 35px;
                border-radius: 0;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
        }
    }

    .name {
        .value {
            margin-top: 12px;
            width: 400px;
            input {
                border: 1px solid #e4e7ed;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
                line-height: 17px;
                color: #606266;
            }
        }
    }

    .sex {
        .value {
            margin-top: 12px;
            width: 400px;
            .el-select {
                width: 100%;
                .el-input__inner {
                    border: 1px solid #e4e7ed;
                    box-sizing: border-box;
                    border-radius: 4px;
                    font-size: 14px;
                    line-height: 17px;
                    color: #606266;
                }
            }
        }
    }
}

.butt {
    width: 264px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    font-size: 14px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    background: #eda200;
    box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
    border-radius: 4px;
    margin: 56px 0 32px 0;
}
</style>
