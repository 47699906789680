<template>
    <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/OrderDetail', query: { id: item.id } })">
            <img :src="item.picUrl" alt="" />
            <div class="right">
                <div class="title">{{ item.goodsName }}</div>
                <div class="bottom">
                    <div class="jifen">{{ item.score }}积分</div>
                    <div class="numbers">X{{ item.quantity }}</div>
                </div>
				<el-button class="confirm-btn" type="danger" @click.stop="confirm(index)" v-if="item.status==2">确认收货</el-button>
            </div>
        </div>
        <el-pagination @size-change="getOrderList" @current-change="getOrderList" :current-page.sync="page" :page-sizes="[10, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getOrderList, updateOrder } from '@/api';
export default {
    name: 'Order',
    data() {
        return {
            tabs: 0,
            limit: 10,
            page: 1,
            total: 0,
            list: [],
        };
    },
    created() {
        this.getOrderList();
    },

    methods: {
        getData() {
            console.log(this.limit, this.page);
        },
        getOrderList() {
            let data = {
                pageNum: this.page,
                pageSize: this.limit,
                userId: JSON.parse(localStorage.getItem('userInfo')).userId,
            };
            getOrderList(data).then((res) => {
                console.log(res);
                this.total = res.total;
                this.list = res.rows;
            });
        },
		confirm(index){
			let item = this.list[index];
			updateOrder({id:item.id,status:3}).then(res=>{
				item.status = 3;
				this.$message.success('确认收货成功', 'success');
			})
		}
    },
};
</script>

<style scoped lang="less">
.list {
    margin-top: 24px;

    .item {
        display: flex;
        padding: 24px;
        margin-top: 12px;
        background: #fff;
        border: 1px solid #ebebeb;

        img {
            width: 140px;
            height: 140px;
            border-radius: 4px;
        }

        .right {
            flex: 1;
            padding-left: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
                width: 100%;
                .ellipsisLine(2);
            }

            .bottom {
                .jifen {
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 25px;
                    color: #eda200;
                }

                .numbers {
                    font-size: 16px;
                    line-height: 23px;
                    color: #666666;
                    margin-top: 12px;
                }
            }
			
			.confirm-btn {
				width: 100px;
			}
        }
    }
}
</style>
