<template>
    <div>
        <div class="count">
            <div class="left">
                <div class="top">
                    <img src="@/assets/images/Frame625.png" />
                    <span>可用积分</span>
                </div>
                <div class="bottom">{{ userInfo.score }}</div>
            </div>
            <div class="left" >
				<div class="top" @click="ruleVisible = true">
					<img src="@/assets/images/Frame1212.png" />
					<div>积分规则</div>
				</div>
				<div class="bottom">
					<el-date-picker value-format="yyyy-MM" v-model="date" type="month" @change="integralChange"></el-date-picker>
					
				</div>
			</div>
		</div>
		<div class="list">
			<div class="item">
			   <div class="title">
				   <div>{{ date.split('-')[0] + '年' + date.split('-')[1] + '月' }}</div>
				   <div>收入{{ data.in || 0 }}积分&nbsp;&nbsp;&nbsp;&nbsp;支出{{ data.out || 0 }}积分</div>
			   </div>
				<div class="box">
					<div class="row" v-for="(item, index) in data.records" :key="index">
						<div class="left">
							<img v-if="item.operType == 0" src="@/assets/images/Group692.png" alt="" />
							<img v-else src="@/assets/images/Group903.png" alt="" />
							<div>{{ arr[item.itemType] }}</div>
						</div>
						<div class="right">
							<div class="time">{{ item.createTime }}</div>
							<div class="data jia">{{ item.operType == 0 ? '+' : '-' }}{{ item.integral }}积分</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <el-pagination @size-change="getRecordList" @current-change="getRecordList" :current-page.sync="page" :page-sizes="[10, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination> -->

		<el-dialog title="积分规则" :visible.sync="ruleVisible" width="20%" :modal="false" class="dialog">
			<div class="item">
				<div>
					<span class="title">新用户注册</span>
				</div>
				<span class="jifen">+10积分</span>
			</div>
			<div class="item">
				<div>
					<span class="title">签到</span>
					<span class="detail">每日只记录一次</span>
				</div>
				<span class="jifen">+5积分</span>
			</div>
			<div class="item">
				<div>
					<span class="title">完善资料</span>
					<span class="detail">只记录一次</span>
				</div>
				<span class="jifen">+20积分</span>
			</div>
			<div class="item">
				<div>
					<span class="title">连续签到3天</span>
				</div>
				<span class="jifen">+10积分</span>
			</div>
			<div class="item">
				<div>
					<span class="title">连续签到7天</span>
				</div>
				<span class="jifen">+20积分</span>
			</div>
			<div class="item">
				<div>
					<span class="title">参加活动</span>
				</div>
				<span class="jifen">+10积分</span>
			</div>
			<div class="item">
				<div>
					<span class="title">培训学习</span>
				</div>
				<span class="jifen">+10积分</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="ruleVisible = false">取 消</el-button>
				<el-button type="primary" @click="ruleVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { getRecordList } from '@/api';
import { parseTime } from '@/utils/utils.js'
export default {
	name: 'Integral',
	data() {
		return {
			tabs: 0,
			limit: 12,
			page: 1,
			ruleVisible: false, //积分规则弹窗
			userInfo: JSON.parse(localStorage.getItem('userInfo')),
			date: parseTime(new Date(), '{y}-{m}'),
			data: {},
			total: 0,
			arr: ['新用户注册', '完善资料', '参加活动', '培训学习', '每日签到', '连续签到三天', '连续签到七天', '商品兑换', '取消学习', '取消报名活动'],
		};
	},
	created() {
		this.getRecordList();
	},
	methods: {
		integralChange(e) {
			debugger;
			this.date = e;
			this.getRecordList();
		},
		getRecordList() {
			this.data = {};
			getRecordList({queryMonth: this.date}).then((res) => {
				this.data = res.data;
			});
		},
	},
};
</script>

<style scoped lang="less">
.count {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px;
    background: #fff;
    border: 1px solid #ebebeb;

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .top {
            display: flex;
            align-items: center;

            span {
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: #333333;
                flex: none;
                order: 1;
                flex-grow: 0;
            }

            img {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }

        .bottom {
            font-weight: 700;
            font-size: 26px;
            line-height: 38px;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin-top: 7px;
        }
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
        }

        div {
            font-size: 13px;
            line-height: 19px;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }
}

.list {
    .item {
        .title {
            padding: 16px 0 14px 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                font-weight: 500;
                flex: none;
                order: 1;
                flex-grow: 0;
                font-size: 16px;
                line-height: 23px;
                color: #333333;

                &:last-child {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                    margin-top: 4px;
                }
            }
        }

        .box {
            background: #fff;
            border: 1px solid #ebebeb;

            .row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 12px;

                .left {
                    display: flex;
                    align-items: center;

                    img {
                        width: 32px;
                        height: 32px;
                        margin-right: 16px;
                    }

                    div {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #333333;
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
                }

                .right {
                    width: 50%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .time {
                        font-size: 13px;
                        line-height: 19px;
                        color: #999999;
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }

                    .data {
                        font-size: 14px;
                        line-height: 20px;
                        text-align: right;
                        flex: none;
                        order: 2;
                        flex-grow: 0;

                        &.jia {
                            color: #fd8f30;
                        }

                        &.jian {
                            color: #6498fd;
                        }
                    }
                }
            }
        }
    }
}

.dialog {
    padding-top: 8px;

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid #ededed;
        div {
            display: flex;
            flex-direction: column;
            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                flex: none;
                order: 0;
                flex-grow: 0;
            }
            .detail {
                font-size: 13px;
                line-height: 19px;
                color: #999999;
                flex: none;
                order: 1;
                flex-grow: 0;
                margin-top: 2px;
            }
        }
        .jifen {
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            color: #fd8f30;
        }
    }
}
::v-deep .el-dialog__body {
    padding: 0 26px 24px 26px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}
</style>
