<template>
    <div>
        <div class="filter">
            <div class="item">
                <div class="title">类型选择：</div>
                <el-select v-model="type" placeholder="请选择">
                    <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="title">预约状态：</div>
                <el-select v-model="status" placeholder="请选择">
                    <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="title">时间：</div>
                <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ name: 'venueReservationDetail', query: { id: item.libraryId } })">
                <img class="active" :src="item.libraryPicUrl" alt="" />
                <div class="info">
                    <div class="title">{{ item.libraryName }}</div>
                    <view :class="item.status !== 2 ? 'status' : 'status huise'">
                        <text>{{ statusList[item.status + 1].label }}</text>
                    </view>
                    <!--                    <div class="address">-->
                    <!--                        <span>使用面积：{{item.}}平</span>-->
                    <!--                    </div>-->
                    <div class="address">
                        <span>预约时间：{{ item.bookingTime }}</span>
                    </div>
                    <div class="address">
                        <span>预约场次：{{ item.startTime }}-{{ item.endTime }}</span>
                    </div>
					<div class="go" @click.stop="gotoEvaluate(item.id,item.libraryId)" v-if="item.status==5">去评价</div>
					<div v-else style="height: 44px;"></div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getData" @current-change="currentChange" :current-page.sync="page" :page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
		<evaluate-info :ruleVisible.sync="infoDialogVisible" :type="0" :relevanceId="relevanceId" :id="libraryId" @evaluateSuccess="getData"></evaluate-info>
	</div>
</template>

<script>
	import evaluateInfo from './evaluateInfo.vue'
export default {
    name: 'Collection',
	components: {evaluateInfo},
    data() {
        return {
            limit: 12,
            page: 1,
            type: -1,
            typeIdx: -1,
            typeList: [
                { label: '全部', value: -1 },
                { label: '参观游玩',value: 0},
                { label: '团体使用',value: 1},
                { label: '文化馆',value: 4},
            ],
            status: -1,
            statusIdx: -1,
            statusList: [
                { label: '全部', value: -1 },
                { label: '待审核', value: 1 },
                { label: '未开始', value: 2 },
                { label: '进行中', value: 3 },
                { label: '已结束', value: 4 },
                { label: '审核失败', value: 5 },
				{ label: '已完成', value: 6 },
				{ label: '已评价', value: 7 },
            ],
            time: '',
            startTime: '', // 开始时间
            endTime: '', // 结束时间
            list: [], // 列表
            total: 0,
			userId:'',
			infoDialogVisible: false,
			relevanceId: '',
			libraryId: ''
        };
    },
    watch: {
        type(val) {
            this.typeIdx = val;
            this.list = [];
            this.getData();
        },
        status(val) {
            this.statusIdx = val;
            this.list = [];
            this.getData();
        },
        time(val) {
            this.startTime = val[0];
            this.endTime = val[1];
            this.list = [];
            this.getData();
        },
    },
    created() {
		let userInfo = JSON.parse(localStorage.getItem('userInfo'));
		this.userId = userInfo.userId;
        this.getData();
    },
    methods: {
        getData() {
            let params = {
                pageSize: this.limit,
                pageNum: this.page,
                userId: this.userId,
            };
            if (this.typeIdx>=0) {
                params.label = this.typeIdx;
            }
            if (this.statusIdx>=0) {
                params.status = this.statusIdx;
            }
            if (this.startTime && this.endTime && new Date(this.startTime).getTime() <= new Date(this.endTime).getTime()) {
                // params.startTime = new Date(this.startTime).getFullYear() + '-' + (new Date(this.startTime).getMonth() + 1) + '-' + new Date(this.startTime).getDate() + ' 00:00:00';
                // params.endTime = new Date(this.endTime).getFullYear() + '-' + (new Date(this.endTime).getMonth() + 1) + '-' + new Date(this.endTime).getDate() + ' 23:59:59';
				params.bookingStartTime = this.startTime;
				params.bookingEndTime = this.endTime;
			}
            this.$hao.getLibraryBookingList(params).then((res) => {
                this.list = res.rows;
                this.total = res.total;
            });
        },
        goDetail() {
            this.$router.push({ path: '/VenueDetail' });
        },
        currentChange(val) {
            //滚动到页面顶部
            window.scrollTo(0, 0);
            this.pageNum = val;
            this.getData();
        },
		gotoEvaluate(id,libraryId){
			this.relevanceId = id;
			this.libraryId = libraryId;
			this.infoDialogVisible = true;
		}
    },
};
</script>

<style scoped lang="less">
::v-deep .is-active {
    background: white !important;
}
::v-deep .el-date-editor--daterange.el-input__inner {
    width: max-content;
}

.filter {
    display: flex;
    align-items: center;
    padding: 24px 0;

    .item {
        display: flex;
        align-items: center;

        .title {
            display: flex;
            width: max-content;
        }

        .el-select {
            width: 160px;
            margin-right: 24px;

            .el-input__inner {
                border: 1px solid #e4e7ed;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
                line-height: 17px;
                color: #606266;
            }
        }
    }
}
.huise {
    color: #c7c7c7;
    text {
        color: #c7c7c7;
        border-color: #c7c7c7;
    }
}
.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;
        position: relative;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                height: 46px;
                color: #333333;
                width: 100%;
                .ellipsisLine(2);
            }
            .status {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 16px;
                line-height: 23px;
                color: #ffffff;
                padding: 4px 12px;
                background: #f33f3e;
                border-radius: 2px 4px;
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 10px;
                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }
        }
    }
}
	.go {
		margin-top: 10px;
        height: 44px;
        box-shadow: 0px 2px 4px rgba(14, 79, 71, 0.2);
        background: #eda200;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #ffffff;
    }
</style>
