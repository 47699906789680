<template>
    <div>
        <div class="tabs">
            <span :class="{ active: tabs === 0 }" @click="tabs = 0">疑难解答</span>
            <span :class="{ active: tabs === 1 }" @click="tabs = 1">意见反馈</span>
            <span :class="{ active: tabs === 2 }" @click="tabs = 2">关于我们</span>
        </div>
        <template v-if="tabs === 0">
            <div class="list">
                <div class="item" v-for="(item, index) in list" :key="index" @click="openDetail(item)">
                    <div class="title">{{ item.title }}</div>
                    <div class="desc">{{ item.content }}</div>
                </div>
            </div>
            <el-pagination @size-change="getTroubleshootingList" @current-change="getTroubleshootingList" :current-page.sync="page" :page-sizes="[10, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
        </template>
        <div v-else-if="tabs === 1" class="fankui">
            <el-input type="textarea" class="textarea" :rows="10" placeholder="欢迎留下你的宝贵意见" v-model="textarea"></el-input>
            <div class="butt" @click="addFeedback">提交</div>
        </div>
        <div v-else-if="tabs === 2" class="about">
            <div class="abt1">ABOUT US</div>
            <div class="abt2">关于我们</div>
            <div class="abt3">{{ detail.description }}</div>
        </div>
    </div>
</template>
<script>
import { addFeedback, getLibraryDetail, getTroubleshootingList } from '@/api';

export default {
    name: 'Collection',
    data() {
        return {
            tabs: 0,
            limit: 10,
            total: 0,
            page: 1,
            list: [],
            textarea: '',
            detail: {},
        };
    },
    created() {
        this.getTroubleshootingList();
        this.getLibraryDetail();
    },
    methods: {
        getData() {
            console.log(this.limit, this.page);
        },
        openDetail(item) {
            this.$alert(item.content, item.title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            });
        },
        getTroubleshootingList() {
            let data = {
                pageNum: this.page,
                pageSize: this.limit,
            };
            getTroubleshootingList(data).then((res) => {
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
        addFeedback() {
            let data = {
                content: this.textarea,
            };
            if (this.textarea === '') {
                this.$message({
                    message: '请输入内容',
                    type: 'warning',
                });
                return;
            }
            addFeedback(data).then((res) => {
                if (res.code === 200) {
                    this.$message({
                        message: '提交成功',
                        type: 'success',
                    });
                }
            });
        },
        getLibraryDetail() {
            let data = {
                id: localStorage.getItem('id'),
            };
            getLibraryDetail(localStorage.getItem('id')).then((res) => {
                // if (res.code === 200) {
                this.detail = res.data;
                console.log('detail', this.detail);
                // }
            });
        },
    },
};
</script>
<style scoped lang="less">
.tabs {
    display: flex;
    align-items: center;
    padding-top: 24px;
    overflow: hidden;
    border-radius: 3px;
    cursor: pointer;
    span {
        padding: 6.5px 10px;
        font-size: 14px;
        line-height: 17px;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #fff;
    }
    .active {
        color: #fff;
        background: #eda200;
    }
}
.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    .item {
        padding: 24px 0;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        .title {
            font-weight: 500;
            font-size: 22px;
            color: rgba(0, 0, 0, 0.8);
        }
        .desc {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
            margin-top: 12px;
            width: max-content;
            .ellipsisLine(2);
        }
    }
}
.fankui {
    padding-top: 24px;
    margin-bottom: 52px;
    position: relative;
    ::v-deep .el-textarea__inner {
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        padding: 24px !important;
    }
    .butt {
        position: absolute;
        right: 24px;
        bottom: 24px;
        background: #eda200;
        border-radius: 2px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        padding: 12px 46px;
        color: #ffffff;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
}
.about {
    display: flex;
    flex-direction: column;
    .abt1 {
        font-weight: 500;
        font-size: 46px;
        line-height: 67px;
        color: #ed6c00;
        margin-top: 24px;
    }
    .abt2 {
        font-size: 24px;
        line-height: 35px;
        color: #ed6c00;
    }
    .abt3 {
        font-size: 16px;
        line-height: 23px;
        color: #666666;
        margin-top: 24px;
    }
}
</style>
