<template>
    <!--创建预约评价-->
    <el-dialog title="预约评价" :visible.sync="dialogVisible" width="40%" class="dialog" append-to-body @close="closeDialog">
		<el-form ref="form" :model="form" label-width="80px" :rules="rules">
		  <el-form-item label="星级" prop="starRating" class="rate-item">
			<el-rate v-model="form.starRating"></el-rate>
		  </el-form-item>
		  <el-form-item label="评价" prop="content">
		    <el-input type="textarea" :rows="4" v-model="form.content" placeholder="请输入评价"></el-input>
		  </el-form-item>
		  <el-form-item label="图片" class="item uploads">
			  <el-upload action="#" list-type="picture-card" :http-request="upDate"
			  	:file-list="pictures" :on-remove="handleRemove" :limit="3"
				:class="{hide: this.pictures.length>=3}">
			  	<i class="el-icon-plus"></i>
			  </el-upload>
		  </el-form-item>
		  <el-form-item label="视频" class="item uploads">
			  <el-upload action="#" list-type="picture-card" :http-request="upDateVideo"
			  	:file-list="videos" :limit="1" :class="{hide: this.videos.length>=1}">
			  	<i class="el-icon-plus"></i>
			  	<div slot="file" slot-scope="{file}">
			  		<video :src="file.url"  @click.stop="" controls></video>
			  		<span class="el-upload-list__item-actions">
			  		<span class="el-upload-list__item-delete" @click="handleRemoveVideo(file)">
			  			<i class="el-icon-delete"></i>
			  		</span>
			  		</span>
			  	</div>
			  </el-upload>
		  </el-form-item>
		  <!-- <div class="item uploads">
		  	<div>
		  		<span class="title">图片：</span>
		  	</div>
		  	<el-upload action="#" list-type="picture-card" :http-request="upDate"
		  		:file-list="pictures" :on-remove="handleRemove" :limit="3">
		  		<i class="el-icon-plus"></i>
		  	</el-upload>
		  </div>
		  <div class="item uploads">
		  	<div>
		  		<span class="title">视频：</span>
		  	</div>
		  	<el-upload action="#" list-type="picture-card" :http-request="upDateVideo" 
		  		:file-list="videos"
		  		:limit="1">
		  		<i class="el-icon-plus"></i>
		  		<div slot="file" slot-scope="{file}">
		  			<video :src="file.url"  @click.stop="" controls></video>
		  			<span class="el-upload-list__item-actions">
		  			<span class="el-upload-list__item-delete" @click="handleRemoveVideo(file)">
		  				<i class="el-icon-delete"></i>
		  			</span>
		  			</span>
		  		</div>
		  	</el-upload>
		  </div> -->
		</el-form>
		<span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="submit">确 定</el-button>
		</span>
    </el-dialog>
</template>

<script>
	import uploadFile from '@/utils/oss/ossClient';
export default {
	name:"evaluateInfo",
	props:['ruleVisible','type','relevanceId','id'],
    data() {
        return {
			dialogVisible: this.ruleVisible,
			form: {
				relevanceId: '',
				starRating: 5,
				content: '',
				picUrl: '',
				videoUrl: '',
				type: this.type
			},
			rules: {
				starRating: [{
					required: true,
					message: '请输入星级',
					trigger: 'blur'
				}],
				content: [{
					required: true,
					message: '请输入评价内容',
					trigger: 'blur'
				}, {
					max: 1000,
					message: '最多只能输入1000个字',
					trigger: 'blur'
				}],
			},
			pictures: [],
			videos: []
        };
    },
	watch: {
		ruleVisible(val) {
			this.dialogVisible = val;
		},
		dialogVisible(val) {
			this.$emit("update:ruleVisible",val);
		}
	},
    methods: {
        submit() {
            this.$refs['form'].validate((valid) => {
            	if (valid) {
					this.form.relevanceId = this.relevanceId;
					this.form.originalRelevanceId = this.id;
					this.form.picUrl = this.pictures.map(ele=>ele.url).join(',');
					this.form.videoUrl = this.videos.map(ele=>ele.url).join(',');
            		this.$hao.addEvaluation(this.form).then((res) => {
            			if (res.code === 200) {
							this.$emit('evaluateSuccess');
            				this.$message({
            					message: '评价成功',
            					type: 'success',
            				});
							this.dialogVisible = false;
            			}
            		});
            	} else {
            		console.log('输入错误!');
            		return false;
            	}
            });
        },
		upDate(e) {
			let loading = this.$loading({
				lock:true,
				text:"上传中",
				spinner:"el-icon-loading",
				background: 'rgba(0, 0, 0, 0.7)'
			})
			uploadFile(e).then((res) => {
				this.pictures.push({ uid: e.file.uid, url: res[0] });
				loading.close();
			});
		},
		handleRemove(file, fileList) {
			this.pictures = fileList;
		},
		// handlePictureCardPreview(file) {
		// 	this.dialogImageUrl = file.url;
		// 	this.dialogVisible1 = true;
		// },
		upDateVideo(e) {
			let loading = this.$loading({
				lock:true,
				text:"上传中",
				spinner:"el-icon-loading",
				background: 'rgba(0, 0, 0, 0.7)'
			})
			uploadFile(e).then((res) => {
				this.videos.push({ uid: e.file.uid, url: res[0] });
				loading.close();
			});
		},
		handleRemoveVideo(file, fileList) {
			let index = this.videos.findIndex(item => {
				if (item.uid==file.uid){
					return true;
				}
			});
			this.videos.splice(index,1);
		},
		closeDialog(){
			this.form = {
				starRating: 5,
				content: '',
				picUrl: '',
				videoUrl: '',
				type: this.type
			};
			this.videos=[];
			this.pictures=[];
		}
    },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
    padding: 24px 24px 12px 24px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}
	.item {
		::v-deep .el-input__inner {
			border-radius: 4px;
		}
	
		div {
			margin-bottom: 12px;
	
			.title {
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				color: #333333;
				flex: none;
				order: 0;
				flex-grow: 0;
			}
	
			img {
				width: 7px;
				height: 17px;
			}
	
			video {
				width: 76px;
				height: 76px;
			}
		}
	}
	
	.uploads {
		::v-deep .el-upload--picture-card {
			margin: 0 10px 10px 0;
			width: 76px;
			height: 76px;
			line-height: 86px;
		}
	
		::v-deep .el-upload-list__item {
			width: 76px;
			height: 76px;
		}
	
		video {
			width: 96px;
			height: 96px;
			margin-right: 5px;
		}
	}
	.rate-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		
		/deep/.el-form-item__content {
			margin-left: 0 !important;
		}
	}
	// .el-upload--picture-card 控制加号部分
	::v-deep.hide .el-upload--picture-card {
	    display: none;
	}
	// 去掉动画效果
	::v-deep .el-list-enter-active,
	::v-deep .el-list-leave-active {
	    transition: all 0s;
	}
	
	::v-deep .el-list-enter, .el-list-leave-active {
	    opacity: 0;
	    transform: translateY(0);
	}
</style>
