<template>
    <div>
        <div class="userinfo">
            <img class="bg" src="@/assets/images/Rectangle1062.png" alt="" />
            <div class="detail">
                <div class="left">
                    <img class="active" :src="userInfo.avatar" alt="" />
                    <div class="info">
                        <div class="name">{{ userInfo.nickName }}</div>
                        <div class="desc" @click="tabs = 8">
                            <img src="@/assets/images/Frame625.png" />
                            <span>我的积分：{{ userInfo.score }}</span>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="item" @click="tabs = 9">
                        <div class="num">{{ community }}</div>
                        <div class="desc">我的社群</div>
                    </div>
                    <div class="item" @click="tabs = 10">
                        <div class="num">{{ posts }}</div>
                        <div class="desc">我的帖子</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav">
            <div class="item" :class="{ active: tabs === 0 }" @click="tabs = 0">
                <img src="@/assets/images/Frame-32.png" alt="" />
                <span>我的收藏</span>
            </div>
            <div class="item" :class="{ active: tabs === 1 }" @click="tabs = 1">
                <img src="@/assets/images/Frame-56.png" alt="" />
                <span>我的作品</span>
            </div>
            <div class="item" :class="{ active: tabs === 2 }" @click="tabs = 2">
                <img src="@/assets/images/Frame-57.png" alt="" />
                <span>个人资料</span>
            </div>
            <div class="item" :class="{ active: tabs === 3 }" @click="tabs = 3">
                <img src="@/assets/images/Frame-58.png" alt="" />
                <span>我的订单</span>
            </div>
            <div class="item" :class="{ active: tabs === 4 }" @click="tabs = 4">
                <img src="@/assets/images/Frame-59.png" alt="" />
                <span>学习记录</span>
            </div>
            <div class="item" :class="{ active: tabs === 5 }" @click="tabs = 5">
                <img src="@/assets/images/Frame-60.png" alt="" />
                <span>场馆记录</span>
            </div>
            <div class="item" :class="{ active: tabs === 6 }" @click="tabs = 6">
                <img src="@/assets/images/Frame-61.png" alt="" />
                <span>活动记录</span>
            </div>
            <div class="item" :class="{ active: tabs === 7 }" @click="tabs = 7">
                <img src="@/assets/images/Frame-62.png" alt="" />
                <span>帮助中心</span>
            </div>
        </div>
        <Collection v-if="tabs === 0"></Collection>
        <Work v-if="tabs === 1"></Work>
        <UserInfo v-if="tabs === 2"></UserInfo>
        <Order v-if="tabs === 3"></Order>
        <Study v-if="tabs === 4"></Study>
        <Venue v-if="tabs === 5"></Venue>
        <Activity v-if="tabs === 6"></Activity>
        <Help v-if="tabs === 7"></Help>
        <Integral v-if="tabs === 8"></Integral>
        <Community v-if="tabs === 9"></Community>
        <Posts v-if="tabs === 10"></Posts>
    </div>
</template>

<script>
import Collection from './components/Collection';
import Work from './components/Work';
import UserInfo from './components/UserInfo';
import Order from './components/Order';
import Study from './components/Study';
import Venue from './components/Venue';
import Activity from './components/Activity';
import Help from './components/Help';
import Integral from './components/Integral';
import Community from './components/Community';
import Posts from './components/Posts';
import { getCommunityAndPostsCount } from '@/api';
import { getUserInfo  } from '@/api';

export default {
    name: 'my',
    components: {
        Collection,
        Work,
        UserInfo,
        Order,
        Study,
        Venue,
        Activity,
        Help,
        Integral,
        Community,
        Posts,
        // userInfo: {},
    },
    data() {
        return {
            tabs: 0,
            community: 0,
            posts: 0,
			userInfo: {},
        };
    },
    created() {
		// this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
		this.getUserInfo();
        this.$nextTick(() => {
            this.tabs = Number(this.$route.query.tabs) || 0;
        });
        this.getCommunityAndPostsCount();
    },
    methods: {
		getUserInfo(){
			getUserInfo().then((res) => {
			    localStorage.setItem('userInfo', JSON.stringify(res.data));
			    this.userInfo = res.data;
			});
		},
        getCommunityAndPostsCount() {
            getCommunityAndPostsCount().then((res) => {
                console.log(res);
                this.community = res.communityCount;
                this.posts = res.postsCount;
            });
        },
    },
};
</script>

<style scoped lang="less">
.userinfo {
    width: 100%;
    position: relative;

    .bg {
        width: 100%;
        height: 200px;
        position: absolute;
        z-index: -1;
    }

    .detail {
        width: 100%;
        padding: 120px 0 24px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;

        .left {
            display: flex;
            align-items: center;

            .active {
                border-radius: 50%;
                width: 56px;
                height: 56px;
                border: 4px solid rgba(255, 255, 255, 0.2);
            }

            .info {
                margin-left: 10px;

                .name {
                    font-weight: 700;
                    font-size: 19px;
                    line-height: 28px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                .desc {
                    display: flex;
                    align-items: center;
                    margin-top: 4px;
                    cursor: pointer;

                    img {
                        width: 18px;
                        height: 18px;
                    }

                    span {
                        font-size: 14px;
                        line-height: 20px;
                        color: #ffffff;
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                        margin-left: 4px;
                    }
                }
            }
        }

        .right {
            display: flex;
            align-items: center;

            .item {
                padding: 0 41.25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;

                .num {
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 24px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                .desc {
                    font-size: 12px;
                    line-height: 17px;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    margin-top: 2px;
                }
            }

            .item:last-child {
                border-left: white solid 1px;
            }
        }
    }
}

.nav {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: white;

    .item {
        display: flex;
        width: 12.5%;
        height: 80px;
        border-bottom: #fff solid 2px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // 选中
        &.active {
            border-bottom: #eda200 solid 2px;
        }

        img {
            width: 24px;
            height: 24px;
        }

        span {
            font-size: 16px;
            line-height: 23px;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin-left: 2px;
        }
    }
}
</style>
