<template>
    <div>
        <div class="filter">
            <div class="item">
                <div class="title">类型选择：</div>
                <el-select @change="(page = 1), getData()" v-model="holdValue" placeholder="请选择">
                    <el-option v-for="item in hold" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="title">预约状态：</div>
                <el-select @change="(page = 1), getData()" v-model="intelligentValue" placeholder="请选择">
                    <el-option v-for="item in intelligent" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="title">时间：</div>
                <el-date-picker @change="(page = 1), getData()" v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/excitingActivitiesDetail', query: { id: item.activityId } })">
                <img class="active" :src="item.picUrl" alt="" />
                <div class="info">
                    <div class="title">{{ item.name }}</div>
                    <div class="status">{{ intelligent[item.status + 1].label }}</div>
                    <div class="tips">
                        <div class="tip">{{ hold[item.type + 1 || 0].label }}</div>
                        <div class="tip" v-if="item.isFree == 1">免费</div>
                    </div>
                    <div class="time">
                        <img src="@/assets/images/Frame-21.png" alt="" />
                        <span>{{ item.createTime }}</span>
                    </div>
                    <div class="address">
                        <img src="@/assets/images/Frame-20.png" alt="" />
                        <span>{{ item.libraryAddress }}</span>
                    </div>
					<div class="go" @click.stop="gotoEvaluate(item.id,item.activityId)" v-if="item.state==1">去评价</div>
					<div v-else style="height: 44px;"></div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getData" @current-change="getData" :current-page.sync="page" :page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
		<evaluate-info :ruleVisible.sync="infoDialogVisible" :type="1" :relevanceId="relevanceId" :id="activityId" @evaluateSuccess="getData"></evaluate-info>
	</div>
</template>

<script>
	import evaluateInfo from './evaluateInfo.vue'
export default {
    name: 'Collection',
	components: {evaluateInfo},
    data() {
        return {
            tabs: 0,
            limit: 12,
            page: 1,
            time: [],
            list: [],
            total: 0,
            holdValue: null,
            hold: [
                {
                    label: '全部',
                    value: null,
                },
                {
                    label: '演出',
                    value: 0,
                },
                {
                    label: '讲座',
                    value: 1,
                },
                {
                    label: '展览',
                    value: 2,
                },
                {
                    label: '会议',
                    value: 3,
                },
            ],
            intelligentValue: null,
            intelligent: [
                {
                    label: '全部',
                    value: null,
                },
                {
                    label: '未开始',
                    value: 0,
                },
                {
                    label: '进行中',
                    value: 1,
                },
                {
                    label: '已结束',
                    value: 2,
                },
            ],
			infoDialogVisible: false,
			relevanceId: '',
			activityId: ''
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            console.log(this.limit, this.page);
            let params = {
                pageSize: this.limit,
                pageNum: this.page,
                type: this.holdValue,
                status: this.intelligentValue,
                userId: JSON.parse(localStorage.getItem('userInfo')).userId,
            };
            if (this.time.length) {
                params.startTime = this.time[0].getFullYear() + '-' + (this.time[0].getMonth() + 1) + '-' + this.time[0].getDate() + ' 00:00:00';
                params.endTime = this.time[1].getFullYear() + '-' + (this.time[1].getMonth() + 1) + '-' + this.time[1].getDate() + ' 23:59:59';
            }
            this.$hao.getActivityRecord(params).then((res) => {
                this.list = res.rows;
                this.total = res.total;
            });
        },
		gotoEvaluate(id,activityId){
			this.relevanceId = id;
			this.activityId = activityId;
			this.infoDialogVisible = true;
		}
    },
};
</script>

<style scoped lang="less">
::v-deep .is-active {
    background: white !important;
}
::v-deep .el-date-editor--daterange.el-input__inner {
    width: max-content;
}

.filter {
    display: flex;
    align-items: center;
    padding: 24px 0;

    .item {
        display: flex;
        align-items: center;

        .title {
            display: flex;
            width: max-content;
        }

        .el-select {
            width: 160px;
            margin-right: 24px;

            .el-input__inner {
                border: 1px solid #e4e7ed;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
                line-height: 17px;
                color: #606266;
            }
        }
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;
        position: relative;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                height: 46px;
                color: #333333;
                width: 100%;
                .ellipsisLine(2);
            }

            .status {
                position: absolute;
                right: 0;
                top: 0;
                padding: 4px 12px;
                font-size: 16px;
                line-height: 23px;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                background: #f33f3e;
                border-radius: 2px 4px;
            }

            .tips {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .tip {
                    padding: 4px 12px;
                    background: rgba(243, 63, 62, 0.1);
                    border-radius: 2px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-right: 8px;
                    font-size: 13px;
                    line-height: 19px;
                    color: #f33f3e;
                }
            }

            .time {
                display: flex;
                align-items: center;
                margin-top: 8px;

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                }

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 8px;

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                }

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }
        }
    }
}

.go {
		margin-top: 10px;
        height: 44px;
        box-shadow: 0px 2px 4px rgba(14, 79, 71, 0.2);
        background: #eda200;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #ffffff;
    }
</style>
