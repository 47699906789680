<template>
    <div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/communityDetail', query: { id: item.id } })">
                <div>
                    <img :src="item.picUrl" />
                    <span>{{ item.name }}</span>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getCommunityList" @current-change="getCommunityList" :current-page.sync="page" :page-sizes="[10, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'Community',
    data() {
        return {
            tabs: 0,
            limit: 12,
            page: 1,
            total: 0,
            list: [],
        };
    },
    created() {
        this.getCommunityList();
		this.total = this.$parent.community;
    },
    methods: {
        getData() {
            console.log(this.limit, this.page);
        },
        getCommunityList() {
            let data = {
                pageNum: this.page,
                pageSize: this.limit,
                userId: JSON.parse(localStorage.getItem('userInfo')).userId,
            };
            this.$hao.getMyCommunitys(data).then((res) => {
                this.list = res.data;
            });
        },
    },
};
</script>

<style scoped lang="less">
.list {
    margin-top: 24px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 30px;
    .item {
        margin-bottom: 56px;
        cursor: pointer;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            width: 100%;
			height: 175px;
            border-radius: 2px;
            filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
        }

        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin-top: 16px;
            width: 90%;
            .ellipsisLine(1);
        }
    }
}
</style>
