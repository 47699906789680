<template>
    <div>
        <div class="filter">
            <div class="item">
                <div class="title">类型选择：</div>
                <el-select v-model="type" placeholder="请选择类型">
                    <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="title">时间：</div>
                <el-date-picker value-format="yyyy-MM-dd" v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index"  @click="$router.push({ path: '/moocLearningDetail', query: { id: item.moocId } })">
				<img v-if="item.vedioUrl!=null" class="active" :src="item.vedioUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" alt="" />
                <img v-else class="active" src="@/assets/images/Group1075.png" />
				<div class="info">
                    <div class="title">{{ item.moocName }}</div>
                    <div class="tips">
                        <div class="tip">{{ typeList[item.moocType].label }}</div>
                        <!--                        <div class="tip">免费</div>-->
                    </div>
                    <div class="time">
                        <span>授课老师：{{ item.teacherName || '' }}</span>
                    </div>
                    <div class="address">
                        <span>开课时间：{{ item.learnTime || '' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getData" @current-change="getData" :current-page.sync="page" :page-sizes="[12, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'Collection',
    data() {
        return {
            limit: 12,
            page: 1,
            type: '',
            typeList: [
                {
                    label: '全部',
                    value: '',
                },
                {
                    label: '书法',
                    value: 1,
                },
                {
                    label: '舞蹈',
                    value: 2,
                },
                {
                    label: '音乐',
                    value: 3,
                },
                {
                    label: '美术',
                    value: 4,
                },
                {
                    label: '摄影',
                    value: 5,
                },
                {
                    label: '文艺鉴赏',
                    value: 6,
                },
                {
                    label: '其他',
                    value: 7,
                },
            ],
            time: [],
            list: [],
            total: 0,
        };
    },
    watch: {
        type() {
            this.getData();
        },
        time() {
            this.getData();
        },
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            let data = {
                pageSize: this.limit,
                pageNum: this.page,
				startTime: '',
				endTime: ''
            };
            if (this.type) {
                data.moocType = this.type;
            }
            if (this.time) {
                // 格林尼治时间格式化年月日
                // data.startTime = this.time[0].getFullYear() + '-' + (this.time[0].getMonth() + 1) + '-' + this.time[0].getDate() + ' 00:00:00';
                // data.endTime = this.time[1].getFullYear() + '-' + (this.time[1].getMonth() + 1) + '-' + this.time[1].getDate() + ' 23:59:59';
				data.startTime  = this.time[0];
				data.endTime  = this.time[1];
				console.log('time----------:'+ this.time);
			}
            this.$hao.getMoocLearnRecordList(data).then((res) => {
                this.list = res.rows;
                this.total = res.total;
            });
        },
    },
};
</script>

<style scoped lang="less">
::v-deep .is-active {
    background: white !important;
}
::v-deep .el-date-editor--daterange.el-input__inner {
    width: max-content;
}

.filter {
    display: flex;
    align-items: center;
    padding: 24px 0;
	
    .item {
        display: flex;
        align-items: center;

        .title {
            display: flex;
            width: max-content;
        }

        .el-select {
            width: 160px;
            margin-right: 24px;

            .el-input__inner {
                border: 1px solid #e4e7ed;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
                line-height: 17px;
                color: #606266;
            }
        }
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;
        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            position: relative;
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                height: 46px;
                color: #333333;
                width: 100%;
                .ellipsisLine(2);
            }

            .tips {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .tip {
                    padding: 4px 12px;
                    background: rgba(243, 63, 62, 0.1);
                    border-radius: 2px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-right: 8px;
                    font-size: 13px;
                    line-height: 19px;
                    color: #f33f3e;
                }
            }

            .time {
                display: flex;
                align-items: center;
                margin-top: 8px;

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 8px;
                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }
        }
    }
}
</style>
