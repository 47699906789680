<template>
    <div>
		<div class="filter">
		    <div class="butt" @click="dialogVisible = true">创建+</div>
		</div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/civilizedCreationDetail', query: { id: item.id } })" >
                <img class="active" :src="item.picUrl" alt="" />
                <div class="info">
                    <div class="title">{{ item.createTitle }}</div>
                    <div class="tips">
                        <div class="tip">{{ options[item.createType].name }}</div>
                    </div>
                    <div class="time">
                        <img src="@/assets/images/Frame-21.png" alt="" />
                        <span>{{ item.createTime || '' }}</span>
                    </div>
                    <!-- <div class="address">
                        <img src="@/assets/images/Frame-20.png" alt="" />
                        <span>{{ item.craeteDec || '' }}</span>
                    </div> -->
                </div>
            </div>
        </div>
        <el-pagination @size-change="getData" @current-change="currentChange" :current-page.sync="page" :page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
		<el-dialog title="作品发布" :visible.sync="dialogVisible" width="600px" append-to-body class="dialog">
			<el-form ref="form1" label-position="top" label-width="80px" :model="form" :rules="rules">
				<el-form-item label="标题：" prop="title">
					<el-input v-model="form.title"></el-input>
				</el-form-item>
				<el-form-item label="类型：" prop="options">
					<el-select v-model="form.options" placeholder="请选择" style="width: 100%">
						<el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="描述：" prop="content">
					<el-input type="textarea" rows="5" placeholder="请输入内容" resize="none" v-model="form.content">
					</el-input>
				</el-form-item>
				<div class="item uploads">
					<div>
						<img src="@/assets/images/Frame652.png" alt="" />
						<span class="title">图片：</span>
					</div>
					<el-upload action="#" list-type="picture-card" :http-request="upDate"
						:file-list="pictures"
						:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :limit="3">
						<i class="el-icon-plus"></i>
					</el-upload>
				</div>
				<div class="item uploads">
					<div>
						<img src="@/assets/images/Frame652.png" alt="" />
						<span class="title">视频：</span>
					</div>
					<el-upload action="#" list-type="picture-card" :http-request="upDateVideo" 
						:file-list="videos"
						:limit="3">
						<i class="el-icon-plus"></i>
						<div slot="file" slot-scope="{file}">
							<video :src="file.url"  @click.stop="" controls></video>
							<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-delete" @click="handleRemoveVideo(file)">
								<i class="el-icon-delete"></i>
							</span>
							</span>
						</div>
					</el-upload>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm('form1')">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible1" append-to-body class="dialog1">
			<img width="50%" :src="dialogImageUrl" alt="" />
		</el-dialog>
    </div>
</template>

<script>
	import uploadFile from '@/utils/oss/ossClient';
export default {
    name: 'Collection',
    data() {
        return {
            tabs: 0,
            limit: 12,
            page: 1,
            list: [],
            total: 0,
            options: [
                {
                    name: '全部',
                    value: '',
                },
                {
                	name: '书法',
                	value: 1,
                },
                {
                	name: '舞蹈',
                	value: 2,
                },
                {
                	name: '音乐',
                	value: 3,
                },
                {
                	name: '美术',
                	value: 4,
                },
                {
                	name: '摄影',
                	value: 5,
                },
                {
                	name: '文艺鉴赏',
                	value: 6,
                },
                {
                	name: '其他',
                	value: 7,
                },
            ],
            statusArr: ['即将开始', '进行中', '已结束'],
			dialogVisible: false,
			form: {
				title: '',
				options: '',
				content: '',
				picUrl: '',
				videoUrl: ''
			},
			rules: {
				title: [{
					required: true,
					message: '请输入标题',
					trigger: 'blur'
				}],
				options: [{
					required: true,
					message: '请选择活动区域',
					trigger: 'change'
				}],
				content: [{
					required: true,
					message: '请输入活动内容',
					trigger: 'blur'
				}],
			},
			dialogImageUrl: '',
			dialogVisible1: false,
			videos: [],
			pictures: []
        };
    },
    created() {
        this.getData();
    },
    methods: {
		submitForm(form1) {
			if (this.videos.length === 0 && this.pictures.length === 0){
				this.$message.error('请上传图片或视频');
				return
			}
			this.$refs[form1].validate((valid) => {
				if (valid) {
					this.dialogVisible = false;
					this.$hao.addCreation({
						createTitle: this.form.title,
						createType: this.form.options,
						picUrl: this.pictures.map((item) => item.url).join(','),
						videoUrl: this.videos.map((item) => item.url).join(','),
						craeteDec: this.form.content,
						libraryId: localStorage.getItem('id')
					}).then((res) => {
						if (res.code === 200) {
							this.$message({
								message: '创建成功',
								type: 'success',
							});
						}
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		upDate(e) {
			uploadFile(e).then((res) => {
				this.pictures.push({ uid: e.file.uid, url: res[0] });
			});
		},
		handleRemove(file, fileList) {
			this.pictures = fileList;
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible1 = true;
		},
		upDateVideo(e) {
			uploadFile(e).then((res) => {
				this.videos.push({ uid: e.file.uid, url: res[0] });
			});
		},
		handleRemoveVideo(file, fileList) {
			let index = this.videos.findIndex(item => {
				if (item.uid==file.uid){
					return true;
				}
			});
			this.videos.splice(index,1);
		},
        getData() {
            this.$hao
                .getMyCreationList({
                    page: this.page,
                    limit: this.limit,
                })
                .then((res) => {
                    this.list = res.data;
                    this.total = res.total;
					this.list.forEach(item => {
						if (item.picUrl) {
							item.picUrl = item.picUrl.split(',')[0];
						}
					})
                });
        },
        currentChange(val) {
            //滚动到页面顶部
            window.scrollTo(0, 0);
            this.pageNum = val;
            this.getData();
        },
    },
};
</script>

<style scoped lang="less">
	.filter {
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    padding-top: 24px;
	    padding-bottom: 24px;
	
	    .butt {
	        padding: 4px 12px;
	        background: #f33f3e;
	        border-radius: 2px;
	        color: #ffffff;
	        cursor: pointer;
	    }
	}
	
.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            position: relative;
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
                width: 81%;
                .ellipsisLine(2);
            }

            .status {
                position: absolute;
                right: 12px;
                top: 12px;
                font-size: 12px;
                line-height: 17px;
                color: #ffffff;
                padding: 4px 6px;
                background: #f33f3e;
                border-radius: 2px;
            }

            .status1 {
                position: absolute;
                right: 12px;
                top: 12px;
                font-size: 12px;
                color: white;
                flex: none;
                padding: 4px 6px;
                order: 0;
                flex-grow: 0;
                box-sizing: border-box;
                background: #dcdcdc;
                border-radius: 2px;
            }

            .tips {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .tip {
                    padding: 4px 12px;
                    background: rgba(243, 63, 62, 0.1);
                    border-radius: 2px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-right: 8px;
                    font-size: 13px;
                    line-height: 19px;
                    color: #f33f3e;
                }
            }

            .time {
                display: flex;
                align-items: center;
                margin-top: 8px;

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                }

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                    .ellipsisLine(1);
                }
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 8px;

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                }

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                    .ellipsisLine(1);
                }
            }
        }
    }
}
.dialog {
		padding-top: 8px;

		.item {
			display: flex;
			flex-direction: column;

			::v-deep .el-input__inner {
				border-radius: 4px;
			}

			div {
				margin-bottom: 12px;

				.title {
					font-weight: 500;
					font-size: 14px;
					line-height: 20px;
					color: #333333;
					flex: none;
					order: 0;
					flex-grow: 0;
				}

				img {
					width: 7px;
					height: 17px;
				}

				video {
					width: 76px;
					height: 76px;
				}
			}
		}

		.uploads {
			::v-deep .el-upload--picture-card {
				margin: 0 10px 10px 0;
				width: 76px;
				height: 76px;
				line-height: 86px;
			}

			::v-deep .el-upload-list__item {
				width: 76px;
				height: 76px;
			}

			.videos {
				display: inline;
			}

			video {
				width: 96px;
				height: 96px;
				margin-right: 5px;
			}
		}
	}

	.dialog1 {
		text-align: center;
	}
</style>
