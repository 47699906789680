<template>
    <div>
        <div class="list1">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/communityPostsDetail', query: { id: item.id } })">
                <div class="top">
                    <div class="left">
                        <img src="https://picsum.photos/60" alt="" />
                        <span>{{ item.nickName }}</span>
                        <span>{{ item.createTime }}</span>
                    </div>
                    <div class="right">
                        <div class="items" @click.stop="collection(index)">
                            <!--                         eslint-disable-next-line -->
                            <img :src="item.collectionId ? require('../../../assets/images/Frame-32.png') : require('../../../assets/images/Frame-31.png')" />
                            <!--<img src="@/assets/images/Frame-31.png" alt="" />-->
                            <span>{{ item.collectionCount || 0 }}</span>
                        </div>
                        <div class="items">
                            <img src="@/assets/images/Group622.png" alt="" />
                            <span>{{ item.commentCount || 0 }}</span>
                        </div>
                        <div class="items" @click.stop="praise(index)">
                            <!-- <img src="@/assets/images/Frame-53.png" alt="" />-->
                            <!--                          eslint-disable-next-line-->
                            <img :src="item.praiseId ? require('../../../assets/images/Frame-30.png') : require('../../../assets/images/Frame-29.png')" />
                            <span>{{ item.praiseCount || 0 }}</span>
                        </div>
                    </div>
                </div>
                <div class="content">{{ item.content }}</div>
                <div class="img">
                    <div class="" v-for="(t, i) in item.picUrl" :key="i" @click.stop="() => {}">
                        <el-image style="width: 100%; height: 130px" :src="t" :preview-src-list="item.picUrl"></el-image>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getPosts" @current-change="getPosts" :current-page.sync="page" :page-sizes="[10, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'Collection',
    data() {
        return {
            tabs: 0,
            limit: 10,
            page: 1,
            total: 0,
            list: [],
        };
    },
    created() {
        this.getPosts();
    },
    methods: {
        getData() {
            console.log(this.limit, this.page);
        },
        getPosts() {
            let data = {
                pageNum: this.page,
                pageSize: this.limit,
                userId: JSON.parse(localStorage.getItem('userInfo')).userId,
            };

            this.$hao.getMyPosts(data).then((res) => {
                res.data.forEach((item) => {
                    item.picUrl = item.picUrl.split(',');
                });
                this.list = res.data;
                this.total = res.count;
            });
        },
		// 收藏
		collection(index) {
			let item = this.list[index];
		    if (item.collectionId === null) {
		        this.$hao.addPraiseCollection({ relatedId: item.id, type: 4 }).then((res) => {
		            this.$message.success('收藏成功');
		            item.collectionId = res.data.id;
		            item.collectionCount++;
		        });
		    } else {
		        this.$hao.delPraiseCollection(item.collectionId).then((res) => {
		            this.$message.success('取消收藏成功');
		            item.collectionId = null;
		            item.collectionCount--;
		        });
		    }
		},
		// 点赞
		praise(index) {
			let item = this.list[index];
			if (item.praiseId === null) {
		        this.$hao.addPraiseCollection({ relatedId: item.id, type: 8 }).then((res) => {
		            this.$message.success('点赞成功');
					item.praiseId = res.data.id;
		            item.praiseCount++;
		        });
		    } else {
		        this.$hao.delPraiseCollection(item.praiseId).then((res) => {
		            this.$message.success('取消点赞成功');
		            item.praiseId = null;
		            item.praiseCount--;
		        });
		    }
		},
    },
};
</script>

<style scoped lang="less">
.list1 {
    margin-top: 24px;
    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-bottom: 12px;
        padding: 24px;

        .top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 8px;
                }

                span {
                    margin-right: 8px;
                    font-weight: 400;
                    font-size: 13px;
                    color: #333333;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                span:last-child {
                    color: #999999;
                }
            }

            .right {
                display: flex;
                align-items: center;

                .items {
                    display: flex;
                    align-items: center;
                    margin-left: 24px;

                    img {
                        width: 18px;
                        height: 18px;
                        margin-right: 4px;
                    }

                    span {
                        font-size: 14px;
                        line-height: 20px;
                        color: #797a87;
                    }
                }
            }
        }

        .content {
            padding: 12px 0;
            font-size: 14px;
            line-height: 17px;
            color: #333333;
        }

        .img {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 4px;
            width: 50%;
            img {
                width: 100%;
                margin-top: 4px;
            }
        }
    }
}
</style>
